import React from "react";
import PropTypes from "prop-types";

const userImage = `${process.env.PUBLIC_URL}/images/pavan-nandan.jpg`; 

function Reply({ userName, userBio, imagePath }) {
  return (
    <section className="flex gap-3 justify-between p-4 sm:p-6 mt-4 sm:mt-6 bg-white rounded-2xl border border-solid shadow-sm border-[color:var(--Cards-Borders-BR-Color-1,#F0F2F5)]">
      <img 
        loading="lazy" 
        src={userImage} 
        alt={`${userName}'s profile`} 
        className="self-start w-6 h-6 rounded-full object-cover" 
      />
      <div className="flex flex-col flex-1">
        <h2 className="text-sm sm:text-sm font-medium leading-5 text-slate-800">
          {userName}
        </h2>
        <p className="mt-2 text-sm sm:text-sm leading-5 text-slate-500">
          {userBio}
        </p>
      </div>
    </section>
  );
}

// Define PropTypes
Reply.propTypes = {
  userName: PropTypes.string.isRequired,
  userBio: PropTypes.string.isRequired,
  imagePath: PropTypes.string.isRequired,
};

export default Reply;