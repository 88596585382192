import React from "react";

const HeaderItem = ({ text, onClick }) => (
  <div 
    className="justify-center self-start py-1 text-base sm:text-lg leading-7 whitespace-nowrap cursor-pointer hover:underline"
    onClick={onClick}
  >
    {text}
  </div>
);

function Header() {
  const refreshPage = () => {
    window.location.reload();
  };

  const openLearnMore = () => {
    window.open("https://linktr.ee/pavanmadiraju", "_blank", "noopener,noreferrer");
  };

  return (
    <section className="flex flex-col justify-center px-4 sm:px-12 py-2 sm:py-4 bg-white text-zinc-800">
      <header className="flex gap-3 sm:gap-5 justify-between items-center w-full">
        <HeaderItem text="Pavan Madiraju" onClick={refreshPage} />
        <HeaderItem text="Connect" onClick={openLearnMore} />
      </header>
    </section>
  ); 
}

export default Header;