import React from "react";
import PropTypes from "prop-types";
//import userImage from './user-avatar.jpg';  // Import the image


function ChatMessage({ message, imagePath }) {
  return (
    <article className="flex gap-3 justify-between p-6 mt-6 items-start md:flex-wrap md:max-w-full">
      <img
          src={`${process.env.PUBLIC_URL}/images/user-avatar.jpg`}
        alt="User's Avatar"
        loading="lazy"
        className="w-6 h-6 rounded-full object-cover"
      />
      <div className="flex flex-col flex-1">
        <header className="mb-2">
          <h2 className="text-sm text-slate-800">You</h2>
        </header>
        <p className="text-sm text-slate-500">
          {message}
        </p>
      </div>
    </article>
  );
}

ChatMessage.propTypes = {
  message: PropTypes.string.isRequired,
  imagePath: PropTypes.string.isRequired,
};

export default ChatMessage;
