import React, { useState, useRef, useEffect } from 'react';
import { Send, AlertCircle } from 'lucide-react';

const Form = ({ onSubmit }) => {
  const [inputValue, setInputValue] = useState('');
  const textareaRef = useRef(null);

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (inputValue.trim()) {
      onSubmit(inputValue);
      setInputValue('');
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      handleSubmit(event);
    }
  };

  const adjustTextareaHeight = () => {
    const textarea = textareaRef.current;
    if (textarea) {
      textarea.style.height = 'auto';
      textarea.style.height = `${Math.min(textarea.scrollHeight, 200)}px`;
    }
  };

  useEffect(adjustTextareaHeight, [inputValue]);

  return (
    <section className="max-w-[780px] w-full mx-auto mt-4">
      <form onSubmit={handleSubmit} className="relative w-full bg-white rounded-lg shadow-sm">
        <div className="flex items-center relative">
          <textarea
            ref={textareaRef}
            value={inputValue}
            onChange={handleInputChange}
            onKeyDown={handleKeyDown}
            className="w-full px-4 py-4 pr-12 text-gray-700 bg-transparent outline-none text-sm resize-none min-h-[56px] max-h-[200px] leading-[1.2]"
            placeholder="What do you want to know ..."
            rows={1}
          />
          <button
            type="submit"
            className="absolute right-3 text-gray-400 hover:text-gray-600 focus:outline-none transition-colors duration-200"
            disabled={!inputValue.trim()}
          >
            <Send size={20} />
          </button>
        </div>
      </form>
      <div className="px-2 py-2 mt-2 text-xs text-gray-500 flex items-center">
        <AlertCircle size={12} className="mr-1 flex-shrink-0" />
        <span>Generated responses may be inaccurate or inappropriate. Assume with discretion.</span>
      </div>
    </section>
  );
};

export default Form;