import React from "react";
const ProfileImage = `${process.env.PUBLIC_URL}/images/pavan-nandan.jpg`; 

const ProfileInfo = ({ name, title, description }) => (
  <section>
    {name && <p className="text-base sm:text-md leading-7 font-bold mt-2">{name}</p>}
    {title && <p className="text-base sm:text-md leading-7">{title}</p>}
    {description && <p className="text-base sm:text-md leading-7 text-zinc-800">{description}</p>}
  </section>
);

const Profile = () => {
  return (
    <div className="max-h-[calc(100vh-16rem)] overflow-y-auto px-4 sm:px-0">
      <main className="justify-center max-w-[780px] mx-auto mt-6 sm:mt-10">
        <article className="flex gap-5 flex-col sm:flex-row">
          <aside className="flex flex-col items-start w-full sm:w-[27%]">
            <img 
              loading="lazy" 
              src={ProfileImage} 
              alt="Pavan Madiraju" 
              className="aspect-square w-[150px] sm:w-[195px] mt-3 sm:mt-5 object-cover border border-black" 
              style={{borderWidth: '2px', padding: '0px'}} 
            />
            <ProfileInfo 
              name="Pavan Madiraju" 
              title="Senior UX Designer" 
            />
          </aside>
          <section className="w-full sm:w-[78%] mt-4 sm:mt-3.5 mb-6 sm:mb-10">
            <ProfileInfo
              description="Believer of UX alchemy. Designed my way through four cities, three companies, one failure, and countless cups of chai. Currently explaining to my German neighbors why Indian food isn't too spicy."
            />
          </section>
        </article>
      </main>
    </div>
  );
};

export default Profile;