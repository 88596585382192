import React, { useState, useRef, useEffect } from 'react';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import './transitions.css';
import Header from './Header';
import Profile from './Profile';
import QuestionBlock from './QuestionBlock';
import Form from './Form';
import ChatMessage from './ChatMessage';
import Reply from './Reply';

const LoadingIndicator = () => (
  <div className="flex items-center justify-start p-2 sm:p-4">
    <div className="flex space-x-1">
      <div className="w-2 h-2 bg-gray-500 rounded-full animate-bounce" style={{ animationDelay: '0s' }}></div>
      <div className="w-2 h-2 bg-gray-500 rounded-full animate-bounce" style={{ animationDelay: '0.2s' }}></div>
      <div className="w-2 h-2 bg-gray-500 rounded-full animate-bounce" style={{ animationDelay: '0.4s' }}></div>
    </div>
  </div>
);

function MyComponent() {
  const [conversation, setConversation] = useState([]);
  const [showProfileAndQuestions, setShowProfileAndQuestions] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const messagesContainerRef = useRef(null);

  const handleQuestionSubmit = async (question) => {
    console.log('Sending question:', question);
    
    setConversation(prevConversation => [...prevConversation, { type: 'question', text: question }]);
    setShowProfileAndQuestions(false);
    setIsLoading(true);
    
    try {
      const response = await fetch('https://my-rag-backend-f1cbaba399ce.herokuapp.com/ask', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ question }),
      });
  
      if (!response.ok) {
        throw new Error(`Failed to fetch: ${response.statusText}`);
      }
  
      const data = await response.json();
      console.log('Data received:', data);
  
      const aiResponse = {
        userName: "Pavan Nandan",
        userBio: data.reply,
        imagePath: process.env.PUBLIC_URL + '/images/pavan-nandan.jpg',
      };
  
      setConversation(prevConversation => [...prevConversation, { type: 'reply', ...aiResponse }]);
    } catch (error) {
      console.error('Error fetching question:', error);
      // Optionally, add an error message to the conversation here
    } finally {
      setIsLoading(false);
    }
  };

  const handleQuestionClick = (question) => {
    handleQuestionSubmit(question);
  };

  useEffect(() => {
    if (messagesContainerRef.current) {
      const element = messagesContainerRef.current;
      element.scrollTop = element.scrollHeight;
    }
  }, [conversation, isLoading]);

  const messageTransitionTimeout = {
    enter: 300,
    exit: 300
  };

  return (
    <div className="flex flex-col h-screen text-base">
      <Header />
      <div className="flex-1 overflow-hidden">
        <div 
          ref={messagesContainerRef} 
          className="h-full overflow-y-auto bg-neutral-100 p-2 sm:p-4"
          style={{ maxHeight: 'calc(100vh - 10rem)' }}
        >
          {showProfileAndQuestions && <Profile />}
          <div className="space-y-2 max-w-[780px] mx-auto">
            <TransitionGroup>
              {conversation.map((entry, index) => (
                <CSSTransition
                  key={index}
                  timeout={messageTransitionTimeout}
                  classNames="message"
                  appear
                >
                  {entry.type === 'question' ? (
                    <ChatMessage message={entry.text}
                    imagePath={process.env.PUBLIC_URL + '/images/user-avatar.jpg'} />
                  ) : (
                    <Reply userName={entry.userName} userBio={entry.userBio} />
                  )}
                </CSSTransition>
              ))}
            </TransitionGroup>
            {isLoading && <LoadingIndicator />}
          </div>
        </div>
      </div>
      <div className="bg-neutral-100 p-2 sm:p-4">
        {showProfileAndQuestions && (
          <div className="hidden sm:block">
            <QuestionBlock onQuestionClick={handleQuestionClick} />
          </div>
        )}
        <Form onSubmit={handleQuestionSubmit} />
      </div>
    </div>
  );
}

export default MyComponent;