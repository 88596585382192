import React from 'react';
import { Star, Briefcase, PenTool, Building } from 'lucide-react';

const QuestionCard = ({ questionText, onClick, icon: Icon }) => {
  return (
    <section 
      className="group px-3 py-2.5 bg-white rounded-md border border-solid border-gray-200 text-left cursor-pointer hover:bg-gray-50 transition-all duration-200 ease-in-out flex items-center space-x-3"
      onClick={() => onClick(questionText)}
    >
      <Icon size={16} className="text-gray-400 group-hover:text-gray-600 transition-colors duration-200 flex-shrink-0" />
      <span className="flex-grow line-clamp-2 text-sm text-gray-700 group-hover:text-gray-900 transition-colors duration-200">{questionText}</span>
    </section>
  );
};

const QuestionBlock = ({ onQuestionClick }) => {
  const questions = [
    { text: "What's your highlight in your career?", icon: Star },
    { text: "Where have you worked before?", icon: Briefcase },
    { text: "Tell me about yourself in the form of a poem", icon: PenTool },
    { text: "Where do you work now?", icon: Building }
  ];

  return (
    <main className="grid grid-cols-1 sm:grid-cols-2 gap-3 max-w-[780px] mx-auto mt-4 px-4 sm:px-0">
      {questions.map((question, index) => (
        <QuestionCard 
          key={index} 
          questionText={question.text} 
          onClick={onQuestionClick}
          icon={question.icon}
        />
      ))}
    </main>
  );
};

export default QuestionBlock;