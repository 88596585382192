import React from 'react';
import MyComponent from './MyComponent'; // Assuming MyComponent.js is in the src/ directory
import './tailwind.css';


function App() {
  return (
    <div className="App">
      <MyComponent />
    </div>
  );
}

export default App;
